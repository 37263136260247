/* eslint-disable @typescript-eslint/no-var-requires */
const HttpBackend = require('i18next-http-backend/cjs');
const { resolveTranslations, locales } = require('./utils/fetch-translations');

/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
  debug: false,
  serializeConfig: false,
  i18n: {
    defaultLocale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE || 'pl',
    locales,
    localeDetection: false,
  },
  use: [HttpBackend],
  backend: {
    loadPath: '{{lng}}', // {{lng}} will be replaced by the current locale in the request
    request: async (options, lng, payload, callback) => {
      resolveTranslations(lng).then(translations => {
        callback(null, {
          data: translations,
          status: 200,
        });
      });
    },
    reloadInterval: typeof window === 'undefined' && 60_000,
  },
};
